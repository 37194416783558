import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LangLink from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Map from '../components/map';


import list_field from '../assets/svg/list-field.svg';
import laser from '../assets/images/laser.png';

import {
    Container,
    Row,
    Col,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from 'reactstrap';

class ServicesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            page: 0,
            data: {
                "naziv": "",
                "kat_id": 0,
                "opis": ""
            },
            services: []
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        if (this.props[0].match.params.alias !== undefined) {
            this.setState({ page: 1 })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }
    }

    render() {
        return (
            <div className="page-into">
                <section>
                    <Container>
                        <div className="page-circle"></div>
                        <div className="page-circle2"></div>
                        <div className="page-circle3"></div>
                        <div className="page-circle4"></div>
                        <div className="page-circle5"></div>
                        <div className="page-circle6"></div>
                    </Container>
                </section>
                <section className="page-content">
                    <Container>
                        <Row >

                            <Col lg="12" className="service-content">
                                <div className="title-wrap">
                                    <div className="title">
                                        <h1>{this.state.data.naziv}</h1>
                                    </div>
                                </div>
                                <Row>
                                    {
                                        this.state.services.map((service, idx) => {
                                            return (
                                                <Col key={idx} lg="4">
                                                    <div className="service-field">
                                                        <img src={laser} />
                                                        <div className="text">
                                                            <h3>{service.naziv}</h3>
                                                            <p>{service.opis}</p>
                                                            <a href={"/usluga/" + service.usluga_id}><h4>Saznajte više </h4></a>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                    
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section >
                    <Container fluid>
                        <Row className="location-map">
                            <Map {...this.props} />
                        </Row>
                    </Container>
                </section>
            </div >
        );
    }
}

export default Page(ServicesPage);