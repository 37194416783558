import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import { api_url } from '../config/api';

import {
    Container,
    Row,
    Col,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown
} from 'reactstrap';

import right_arrow from '../assets/svg/right-arrow.svg'

import hamburger_icon from '../assets/svg/hamburger-icon.svg'
import dropdown_list from '../assets/svg/dropdown-list.svg'
import logo from '../assets/svg/logo.svg';
import close_ico from '../assets/svg/close-ico.svg';


class HomeHeader extends Component {

    constructor(props) {
        super(props);

        this.getServices = this.getServices.bind(this);
        this.state = {
            services: []
        };
    }
    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.getServices();
    }

    getServices() {
        fetch(api_url + '/kategorija/naziv', {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((data) => {
            this.setState({
                services: data
            })
        })
    }

    render() {
        return (
            <header>
                <Container>
                    <Row>
                        <Col lg="2" xs="10" className="logo">
                            <Link to='/'><Isvg src={logo} /></Link>
                        </Col>
                        <Col lg={{ size: 10 }} xs={{ size: 2 }} className='hamburger' onClick={() => { this.setState({ rightMenu: !this.state.rightMenu }) }}>
                            <Isvg src={hamburger_icon} />
                        </Col>
                        <Col lg="10">
                            <ul className="navigation">
                                <li className={this.props[0].location.pathname == '/' ? 'active' : ''} ><Link to='/'>Početna</Link></li>
                                <li className={this.props[0].location.pathname.indexOf('/vijesti') === 0 ? 'active' : ''}><Link to='/vijesti'>Vijesti</Link></li>
                                <li className={this.props[0].location.pathname == '/usluge' || this.props[0].location.pathname == `/usluge/${this.props[0].match.params.alias}` ? 'active' : ' without-hover'}>
                                    <UncontrolledDropdown>
                                        <DropdownToggle className={this.props[0].location.pathname == '/usluge' || this.props[0].location.pathname == `/usluge/${this.props[0].match.params.alias}` ? 'activee' : ''}>
                                            Usluge
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                this.state.services.map((service, idx) => {
                                                    return (
                                                        <DropdownItem key={idx}><Link to={`/usluge/` + service.kat_id}> <Isvg src={dropdown_list} />{service.naziv}</Link></DropdownItem>
                                                    )
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </li>
                                <li className={this.props[0].location.pathname.indexOf('/galerija') === 0 ? 'active' : ''}><Link to='/galerija'>Galerija</Link></li>
                                <li>
                                    <Link to="/zakazi-pregled">
                                        <button className={this.props[0].location.pathname == '/zakazi-pregled' ? "button active-button" : "button"}>
                                            Zakažite pregled
                                            {
                                                this.props[0].location.pathname != '/zakazi-pregled' ?
                                                    <Isvg src={right_arrow} />
                                                    :
                                                    null
                                            }
                                        </button>
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                {/*mobile*/}                               
                <div className={this.state.rightMenu ? 'mobile-right-menu mobile-right-menu-open' : 'mobile-right-menu'}>
                    <div className="content" ref={(node) => this.rightMenuRef = node}>
                        <button className="close_menu" onClick={() => { this.setState({ rightMenu: !this.state.rightMenu }) }}><Isvg src={close_ico} /></button>
                        <ul className="navigation">
                            <li className={this.props[0].location.pathname == '/' ? 'active' : ''} ><Link to='/'>Početna</Link></li>
                            <li className={this.props[0].location.pathname.indexOf('/vijesti') === 0 ? 'active' : ''}><Link to='/vijesti'>Vijesti</Link></li>
                            <li className={this.props[0].location.pathname == '/page/servis' ? 'active' : ' without-hover'}>
                                <UncontrolledDropdown>
                                    <DropdownToggle>
                                        Usluge
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            this.state.services.map((service, idx) => {
                                                return (
                                                    <DropdownItem key={idx}><Link to={`/usluge/` + service.kat_id}> <Isvg src={dropdown_list} />{service.naziv}</Link></DropdownItem>
                                                )
                                            })
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                            <li className={this.props[0].location.pathname.indexOf('/galerija') === 0 ? 'active' : ''}><Link to='/galerija'>Galerija</Link></li>
                            <li>
                                <Link to="/zakazi-pregled"><button className="button3">Zakažite pregled <Isvg src={right_arrow} /></button></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        );
    }
}


export default HomeHeader;
