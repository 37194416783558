import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';

import lips from '../assets/svg/lips.svg';
import zub from '../assets/images/zub.png';
import blog from '../assets/images/blogImage.png';
import service from '../assets/images/serviceImage.png';
import gallery from '../assets/images/hijaluronski-fileri.jpg';
import tim from '../assets/images/tim.png';
import navodnici from '../assets/images/navodnici.png';
import close_ico from '../assets/svg/close-ico.svg';

import Map from '../components/map';

import BlogArticle from '../components/blogArticle';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


class HomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            services: [],
            workers: [],
            gallery: null,
            latestBlog: [],
            data: {
                "naziv": "ZU \"dr Verica\" Janja",
                "opis": "Estetsko-Stomatološki centar se brine za Vaš osmijeh",
                "o_nama": "Nalazimo se u Janji, nadomak Bijeljine. Pružamo stomatološke i zubotehničke usluge visokog kvaliteta. Nudimo široku paletu usluga iz estetske i rekonstruktivne stomatologije, uz najsavremeniju opremu i materijale.",
                "o_timu": "",
                "vlasnik": "",
                "adresa": "Bijeljinska 107, Janja",
                "br_tel": "",
                "br_mob": "",
                "email": "",
                "slika_naslovna": "naslovna_slika.png",
                "slika_tim": "tim_slika.png",
                "fb_url": "https://www.facebook.com/drverica/",
                "insta_url": "https://www.instagram.com/dr_verica/"
            },
            modal: false
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
    }

    next = () => {
        this.slider.slickNext();
    }
    previous = () => {
        this.slider.slickPrev();
    }
    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: typeof window !== 'undefined' && window.innerWidth < 768 ? 1 : 3,
            slidesToScroll: 1,
            arrows: false,
            className: 'slider',
            autoplay: true,
            autoplaySpeed: 3000,
        };
        return (
            <div>
                <section className="home-into">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <div className="title">
                                    <h1>{this.state.data.opis}</h1>
                                </div>
                                <div className="home-info">
                                    <Isvg src={lips} />
                                    <div className="info-text">
                                        <p align="justify">
                                            {this.state.data.o_nama}
                                        </p>
                                    </div>
                                </div>
                                <div className="home-buttons">
                                    <Link to="/zakazi-pregled"><button className="button">Zakažite pregled</button></Link>
                                    <a href="#usluge"><button className="button2">Usluge</button></a>
                                </div>
                                <div className="home-social">
                                    <h6 className="first-social"><a href={this.state.data.insta_url} target="_blank">Instagram</a></h6>
                                    <h6><a href={this.state.data.fb_url} target="_blank">Facebook</a></h6>
                                </div>
                                <div className="tooth">
                                    <img src={zub} />
                                </div>
                            </Col>

                            <Col lg="6">
                                <div className="phone-numbers">
                                    <h5>{this.state.data.br_tel}</h5>
                                    <h4>‎{this.state.data.br_mob}</h4>
                                </div>
                            </Col>
                            <div className="home-image-wrap">
                                <div className="home-image">
                                    <div className="home-img">
                                        <img src={tim} />
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <div className="big-circle"></div>
                        <div className="big-circle2"></div>
                    </Container>
                </section>

                <section className="section-one">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="title-wrap">
                                    <div className="title">
                                        <h1>Poslednje vijesti</h1>
                                    </div>
                                </div>
                            </Col>
                            {
                                this.state.latestBlog.map((latestBlog, idx) => {
                                    return (
                                        <Col key={idx} lg="4">
                                            <BlogArticle
                                                image={'/images/' + latestBlog.slika}
                                                title={latestBlog.naziv}
                                                shortDescription={latestBlog.opis}
                                                service_id={latestBlog.vijest_id}
                                            />
                                        </Col>
                                    )
                                })
                            }
                            <Col lg="12">
                                <div className="btn-wrap">
                                    <Link to="/vijesti"><button className="button">Ostale vijesti</button></Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="usluge" className="section-two section-two-home-margin">
                    <Container>
                        <Row className="services-row">
                            <Col lg="12">
                                <div className="title-wrap">
                                    <div className="title">
                                        <h1>Usluge</h1>
                                    </div>
                                </div>
                            </Col>
                            {
                                this.state.services.map((cat, idx) => {
                                    return (
                                        <Col key={idx} lg="4">
                                            <div className="service-item">
                                                <div className="item-title">
                                                    <img src={service} />
                                                    <h3>{cat.naziv}</h3>
                                                </div>
                                                <p>
                                                    {cat.opis}
                                                </p>
                                                <Link to={"/usluge/" + cat.kat_id}>Pogledajte usluge</Link>
                                            </div>
                                        </Col>
                                    )
                                })
                            }

                        </Row>
                    </Container>
                </section>
                <section className="section-three">
                    <Container>
                        <Row>
                            <Col lg="4">
                                <div className="section-three-info">
                                    <h6>Fotografije</h6>
                                    <h4>Galerija estetsko-stomatološkog centra</h4>
                                    <Link to="/galerija"><button className="button2">Galerija</button></Link>
                                </div>
                            </Col>
                            <Col lg="8">
                                {this.state.gallery != null ?
                                    <div className="home-gallery">
                                        <div className="image1">
                                            <img src={'/images/' + this.state.gallery[0].lokacija} />
                                        </div>
                                        <div className="images23">
                                            <div className="image2">
                                                <img src={'/images/' + this.state.gallery[1].lokacija} />
                                            </div>
                                            <div className="image3">
                                                <img src={'/images/' + this.state.gallery[2].lokacija} />
                                            </div>
                                        </div>
                                        <div className="image4">
                                            <img src={'/images/' + this.state.gallery[3].lokacija} />
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section-four">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <div className="information-image">
                                    <img src={gallery} />
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="information-wrap">
                                    <h2>Informacije o našem stručnom timu</h2>
                                    <p align="justify">{this.state.data.o_timu}</p>
                                </div>
                            </Col>
                            <Col lg={{ size: 3, offset: 1 }}>
                                <div className="employee-text">
                                    <h4>Zaposleni</h4>
                                    <h6>Vaš osmijeh je naš uspjeh! </h6>
                                </div>
                            </Col>
                            <Col lg="8">
                                <div >

                                    {
                                        this.state.workers && this.state.workers.length > 3 ?
                                            <Slider ref={c => (this.slider = c)} {...settings} className="employee-wrap">

                                                {
                                                    this.state.workers.map((worker, idx) => {
                                                        return (
                                                            <div key={idx} className="employee" onClick={() => this.setState({ modal: true, worker: worker })}>
                                                                <img src={'/images/workers/' + worker.slika} />
                                                                <h5>{worker.ime + ' ' + worker.prezime}</h5>
                                                                <h6>{worker.titula}</h6>
                                                            </div>
                                                        )
                                                    })
                                                }




                                            </Slider>
                                            :
                                            <div className="employee-wrap mob-col">
                                                {
                                                    this.state.workers.map((worker, idx) => {
                                                        return (
                                                            <div key={idx} className="employee" onClick={() => this.setState({ modal: true, worker: worker })}>
                                                                <img src={'/images/workers/' + worker.slika} />
                                                                <h5>{worker.ime + ' ' + worker.prezime}</h5>
                                                                <h6>{worker.titula}</h6>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section >
                    <Container fluid>
                        <Row className="location-map">
                            <Map {...this.props} />
                        </Row>
                    </Container>
                </section>

                {
                    this.state.modal ?
                        <div className="modal-view">
                            <div className="modal-box">
                                <button className="close_menu" onClick={() => { this.setState({ modal: !this.state.modal }) }}><Isvg src={close_ico} /></button>
                                <img src={'/images/workers/' + this.state.worker.slika} />
                                <div className="modal-doctor-info">
                                    <h1>{this.state.worker.ime + ' ' + this.state.worker.prezime}</h1>
                                    <h5>{this.state.worker.titula}</h5>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.worker.biografija }} />
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

            </div>
        );
    }
}

export default Page(HomePage);