import React, { Component } from 'react';
import Link from '../components/link';
import { api_url } from '../config/api';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Alert,
    Button
} from 'reactstrap';


import Form from '../components/forms/makeAppointmentForm';

import service from '../assets/images/serviceImage.png';


import Map from '../components/map';

import moment from 'moment';


class MakeAppointmentPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            services: [],
            succAlert: false,
            errAlert: false,
            retMsg: ""
        };
    }


    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

    }

    submit = (data) => {
        fetch(api_url + '/zakazi', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status == 200) {
                res.json().then((result) => {
                    this.setState({ succAlert: true });
                    this.setState({ errAlert: false });
                    this.setState({ retMsg: result });
                });
            } else {
                res.json().then((result) => {
                    this.setState({ succAlert: false });
                    this.setState({ errAlert: true });
                    this.setState({ retMsg: result });
                });
            }
        })
    };

    render() {

        return (
            <div className="page-into">
                <section>
                    <Container className="title2">
                        <Row>
                            <Col lg="12">
                                <h1>Zakazivanje</h1>
                            </Col>
                        </Row>
                        <div className="page-circle"></div>
                        <div className="page-circle2"></div>
                        <div className="page-circle3"></div>
                        <div className="page-circle4"></div>
                        <div className="page-circle5"></div>
                        <div className="page-circle6"></div>
                    </Container>
                </section>
                <section className="page-content">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Form onSubmit={this.submit} services={this.state.services} />
                                {this.state.succAlert ?
                                    <div class="alert alert-success" role="alert">
                                        {this.state.retMsg}
                                    </div>
                                    : null
                                }
                                {this.state.errAlert ?
                                    <div class="alert alert-danger" role="alert">
                                        {this.state.retMsg}
                                    </div>
                                    : null
                                }
                            </Col>
                            <Col lg="4">
                                <div className="right-content right-content-gallery">

                                    <div className="work-time">
                                        <h4>Radno Vreme Ordinacije</h4>
                                        <div className="work-days">
                                            <h6><span>Ponedeljak - Petak:</span></h6>
                                            <h6>10:00h - 18:00h</h6>
                                        </div>
                                        <div className="work-days">
                                            <h6><span>Subota:</span></h6>
                                            <h6>Ne radimo</h6>
                                        </div>
                                        <div className="work-days">
                                            <h6><span>Nedelja:</span></h6>
                                            <h6>Ne radimo</h6>
                                        </div>
                                        <p align="justify">
                                            Pregled u estetsko-stomatološkom centru možete
                                            zakazati putem telefona
                                            email- a ili
                                            jednostavnim popunjavanjem
                                            online forme. Nakon što dobijemo
                                            Vaš zahtjev kontaktiraćemo Vas u
                                            najkraćem roku kako bi potvrdili termin.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section-two section-two-blog-margin">
                    <Container>
                        <Row className="services-row">
                            <Col lg="12">
                                <div className="title-wrap">
                                    <div className="title">
                                        <h1>Usluge</h1>
                                    </div>
                                </div>
                            </Col>
                            {
                                this.state.services.map((cat, idx) => {
                                    return (
                                        <Col key={idx} lg="4">
                                            <div className="service-item">
                                                <div className="item-title">
                                                    <img src={service} />
                                                    <h3>{cat.naziv}</h3>
                                                </div>
                                                <p>
                                                    {cat.opis}
                                                </p>
                                                <Link to={"/usluge/" + cat.kat_id}>Pogledajte usluge</Link>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </section>
                <section >
                    <Container fluid>
                        <Row className="location-map">
                            <Map {...this.props} />
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Page(MakeAppointmentPage);