import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Textarea from './fields/textarea';
import Select from './fields/select';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"


const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            additionalAction={additionalAction}
            scope={scope}
            {...input}
            children={children}
        />
    )

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

        <Text
            placeholder={placeholder}
            errorText={touched && error}
            label={label}
            error={touched && error}
            type={type}
            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, services } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="name"
                                component={renderTextField}
                                placeholder={"Ime i prezime".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="email"
                                component={renderTextField}
                                placeholder={"Unesite Vaš email".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="field-wrap">
                            <Field
                                name="phoneNumber"
                                component={renderTextField}
                                placeholder={"Unesite broj telefona".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12">

                        <div className="field-wrap">
                            <Field
                                name="reason"
                                component={renderTextareaField}
                                label={"Navedite razlog zakazivanja".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>
                    <Col lg="12" className="btn-wrap">
                        {this.props.loading ?
                            null
                            :
                            <button className="button2">{'Zakaži'.translate(this.props.lang)}</button>
                        }

                    </Col>
                </Row>
            </form>
        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
