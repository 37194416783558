import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
} from 'reactstrap';

import blog from '../assets/images/blogImage.png';
import gallery from '../assets/images/hijaluronski-fileri.jpg';
import doctor from '../assets/images/doctor.jpg';
import tim from '../assets/images/tim.png';
import Map from '../components/map';
import close_ico from '../assets/svg/close-ico.svg';






class GalleryPage extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            ...props.initialData,
            gallery: [],
            activeIndex: 0

        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }

        }
    }
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }




    render() {

        const { activeIndex } = this.state;
        const slides = this.state.gallery && this.state.gallery.map((item, idx) => {
            return (
                <CarouselItem
                    tag="div"
                    key={idx}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div className="lightbox-item">
                        <img src={'/images/' + item.lokacija} />
                    </div>

                </CarouselItem>
            );
        });

        return (
            <div className="page-into">
                <section>
                    <Container className="title">
                        <Row className="title-row">
                            <Col lg="12">
                                <h1>Galerija</h1>
                            </Col>
                            <div className="page-circle"></div>
                            <div className="page-circle2"></div>
                            <div className="page-circle3"></div>
                            <div className="page-circle4"></div>
                            <div className="page-circle5"></div>
                            <div className="page-circle6"></div>
                        </Row>
                    </Container>
                </section>
                <section className="page-content">
                    <Container>
                        <Row>
                            <Col lg="8">
                                <Row>
                                    {
                                        this.state.gallery && this.state.gallery.map((item, idx) => {
                                            return (
                                                <Col key={idx} lg="4">
                                                    <div className="gallery-image">
                                                        <img src={'/images/' + item.lokacija} onClick={() => {
                                                            let index = idx;
                                                            this.setState({ lightbox: true, activeIndex: index })
                                                        }} />
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }


                                </Row>
                            </Col>
                            <Col lg="4">
                                <div className="right-content right-content-gallery">

                                    <div className="work-time">
                                        <h4>Radno Vreme Ordinacije</h4>
                                        <div className="work-days">
                                            <h6><span>Ponedeljak - Petak:</span></h6>
                                            <h6>10:00h - 18:00h</h6>
                                        </div>
                                        <div className="work-days">
                                            <h6><span>Subota:</span></h6>
                                            <h6>Ne radimo</h6>
                                        </div>
                                        <div className="work-days">
                                            <h6><span>Nedelja:</span></h6>
                                            <h6>Ne radimo</h6>
                                        </div>
                                        <p align="justify">
                                            Pregled u estetsko-stomatološkom centru možete
                                            zakazati putem telefona
                                            email- a ili
                                            jednostavnim popunjavanjem
                                            online forme. Nakon što dobijemo
                                            Vaš zahtjev kontaktiraćemo Vas u
                                            najkraćem roku kako bi potvrdili termin.
                                        </p>
                                        <Link to="/zakazi-pregled"><button className="button">Zakažite Pregled </button></Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {this.state.lightbox ?
                        <div className="lightbox">
                            <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                <Isvg src={close_ico} />
                            </div>
                            <Carousel
                                activeIndex={activeIndex}
                                next={this.next}
                                previous={this.previous}
                                autoPlay={null}
                            >
                                {slides}
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>

                        </div>
                        : null
                    }
                </section>
                <section >
                    <Container fluid>
                        <Row className="location-map">
                            <Map {...this.props} />
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Page(GalleryPage);