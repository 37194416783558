import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class BlogArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <article>
                <img src={this.props.image} />
                <h6>{this.props.title}</h6>
                <p>{this.props.shortDescription}</p>
                <Link to={"/vijesti/"+this.props.service_id}>Pogledaj više</Link>
            </article>
        )
    }
}

export default BlogArticle;