import HomePage from './views/homePage';
import BlogPage from './views/blogPage';
import BlogDetailPage from './views/blogDetailPage';
import ServicesPage from './views/ServicesPage';
import ServiceDetailPage from './views/ServiceDetailPage';
import GalleryPage from './views/galleryPage';
import MakeAppointmentPage from './views/makeAppointmentPage';
import { api_url } from './config/api';

const linkTranslates = {
    'en': {
        '/': '/en',
        '/usluge/:alias': '/en/services/:alias',
        '/usluga/:alias': '/en/service/:alias',
        '/vijesti': '/en/blog',
        '/vijesti/:alias': '/en/blog/:alias',
        '/galerija': '/en/gallery',
        '/zakazi-pregled': '/en/appointment',
    }
}

export const routes = [
    {
        path: "/",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Home'.translate(lang) + ' - dr Verica'.translate(lang),
                description: ''.translate(lang),
                // 'og:image': 'https://api-url/uploads/share.png'
            }
        },
        component: HomePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(api_url + '/ordinacija', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        data
                    }
                })
            },
            (fetchFunction, lang, match) => {
                return fetchFunction(api_url + '/kategorija', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        services: result
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(api_url + '/radnik', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        workers: result
                    }
                })
            },
            (fetchFunction, lang, match) => {
                return fetchFunction(api_url + '/vijest/lp', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        latestBlog: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`${api_url}/slika/home`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        gallery: result
                    }
                })
            }
        ]

    },
    {
        path: "/zakazi-pregled",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Zakazivanje'.translate(lang) + ' - dr Verica'.translate(lang),
                description: ''.translate(lang),
                // 'og:image': 'https://api-url/uploads/share.png'
            }
        },
        component: MakeAppointmentPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(api_url + '/kategorija', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        services: result
                    }
                })

            }
            //,
            // (fetchFunction, lang, match) => {
            //     return fetchFunction('http://127.0.0.1:4000/blog/latest', {
            //         method: 'GET',
            //         headers: {
            //             'content-type': 'application/json'
            //         },

            //     }).then(res => res.json()).then((result) => {

            //         return {
            //             latestBlog: result,
            //         }
            //     })

            // }
        ]
    },

    {
        path: "/vijesti",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Vijesti'.translate(lang) + ' - dr Verica'.translate(lang),
                description: ''.translate(lang),
                // 'og:image': 'https://api-url/uploads/share.png'
            }
        },
        component: BlogPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(api_url + '/vijest', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        blogs: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(api_url + '/kategorija', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        services: result
                    }
                })

            }
        ]
    },
    {
        path: "/vijesti/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Vijesti'.translate(lang) + ' - dr Verica'.translate(lang),
                description: ''.translate(lang),
                // 'og:image': 'https://api-url/uploads/share.png'
            }
        },
        component: BlogDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`${api_url}/vijest/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        data: result
                    }
                })
            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`${api_url}/vijest/lp/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        differentBlogs: result
                    }
                })
            }
        ]
    },
    {
        path: "/usluge/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Usluge'.translate(lang) + ' - dr Verica'.translate(lang),
                description: ''.translate(lang),
                // 'og:image': 'https://api-url/uploads/share.png'
            }
        },
        component: ServicesPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`${api_url}/kategorija/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        data: result
                    }
                })
            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`${api_url}/usluga/kat/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        services: result
                    }
                })
            }

        ]
    },
    {
        path: "/usluga/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Usluga'.translate(lang) + ' - dr Verica'.translate(lang),
                description: ''.translate(lang),
                // 'og:image': 'https://api-url/uploads/share.png'
            }
        },
        component: ServiceDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`${api_url}/usluga/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        data: result
                    }
                })
            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`${api_url}/usluga/lp/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    return {
                        differentServices: result
                    }
                })
            }

        ]
    },
    {
        path: "/galerija",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Galerija'.translate(lang) + ' - dr Verica'.translate(lang),
                description: ''.translate(lang),
                // 'og:image': 'https://api-url/uploads/share.png'
            }
        },
        component: GalleryPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`${api_url}/slika`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },
                }).then(res => res.json()).then((result) => {
                    return {
                        gallery: result
                    }
                })
            }
        ]
    },

];

export function generateRoutes(r) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push(r[i]);

        newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })
    }
    return newRoutes;
}