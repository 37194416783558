import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';

import blog from '../assets/images/blogImage.png';
import service from '../assets/images/serviceImage.png';

import Map from '../components/map';
import BlogArticle from '../components/blogArticle';


class BlogPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            services: [],
            blogs: []
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
    }

    render() {
        return (
            <div className="page-into">
                <section>
                    <Container className="title">
                        <Row>
                            <Col lg="12">
                                <h1>Vijesti</h1>
                            </Col>
                        </Row>
                        <div className="page-circle"></div>
                        <div className="page-circle2"></div>
                        <div className="page-circle3"></div>
                        <div className="page-circle4"></div>
                        <div className="page-circle5"></div>
                        <div className="page-circle6"></div>
                    </Container>
                </section>
                <section className="page-content">
                    <Container>
                        <Row>
                            {
                                this.state.blogs.map((blogItem, idx) => {
                                    return (
                                        <Col key={idx} lg="4">
                                            <BlogArticle
                                                image={'/images/' + blogItem.slika}
                                                title={blogItem.naziv}
                                                shortDescription={blogItem.opis}
                                                service_id={blogItem.vijest_id}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </section>
                <section className="section-two section-two-blog-margin">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="title-wrap">
                                    <div className="title">
                                        <h1>Usluge</h1>
                                    </div>
                                </div>
                            </Col>
                            {
                                this.state.services.map((cat, idx) => {
                                    return (
                                        <Col key={idx} lg="4">
                                            <div className="service-item">
                                                <div className="item-title">
                                                    <img src={service} />
                                                    <h3>{cat.naziv}</h3>
                                                </div>
                                                <p>
                                                    {cat.opis}
                                                </p>
                                                <Link to={"/usluge/" + cat.kat_id}>Pogledajte usluge</Link>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </section>
                <section >
                    <Container fluid>
                        <Row className="location-map">
                            <Map {...this.props} />
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Page(BlogPage);