import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { api_url } from '../config/api';
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';

import logo from '../assets/images/footer-logo.png';
import fb_icon from '../assets/svg/facebook-icon.svg';
import instagram_icon from '../assets/svg/instagram-icon.svg';

export class Footer extends Component {
    constructor(props) {
        super(props);
        this.getContact = this.getContact.bind(this);
        this.getTeam = this.getTeam.bind(this);

        this.state = {
            data: {
                "adresa": "",
                "email": "",
                "br_tel": "",
                "br_mob": "",
                "fb_url": "",
                "insta_url": ""
            },
            team: []
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.getContact();
        this.getTeam();
    }

    getContact() {
        fetch(api_url + '/ordinacija/kontakt', {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((data) => {
            this.setState({
                data: data
            })
        })
    }

    getTeam() {
        fetch(api_url + '/radnik/tim', {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((data) => {
            this.setState({
                team: data
            })
        })
    }

    render() {
        return (
            <footer className="footer">
                <Container>
                    <Row className="footer-margin">
                        <Col lg="3">
                            <div className="footer-field">
                                <h3>Brzi linkovi</h3>
                                <div className="footer-fields">
                                    <Link to="/">
                                        <h4>Početna</h4>
                                    </Link>
                                    <Link to="/vijesti">
                                        <h4>Vijesti</h4>
                                    </Link>
                                    <a href="#usluge"><h4>Usluge</h4></a>
                                    <Link to="/galerija">
                                        <h4>Galerija</h4>
                                    </Link>
                                    <Link to="/zakazi-pregled">
                                        <h4>Zakažite pregled</h4>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="footer-field">
                                <h3>Tim stručnjaka</h3>
                                <div className="footer-fields">
                                    {
                                        this.state.team.map((worker, idx) => {
                                            return (
                                                <h4 key={idx}>{worker.radnik}</h4>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="footer-field">
                                <h3>Kontakt</h3>
                                <div className="footer-fields">
                                    <h4>Adresa: {this.state.data.adresa}</h4>
                                    <h4>E-mail: {this.state.data.email}</h4>
                                    <h4>Mobilni tel: {this.state.data.br_mob}</h4>
                                    <h4>Fiksni tel: {this.state.data.br_tel}</h4>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="footer-logo">
                                <div className="footer-logo-svg">
                                    <img src={logo} />
                                </div>

                                <div className="social-networks">
                                    <a href={this.state.data.fb_url} target="_blank"><Isvg src={fb_icon} /></a>
                                    <a href={this.state.data.insta_url} target="_blank"><Isvg src={instagram_icon} className="insta-footer" /></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="footer-margin footer-bottom-line">
                        <Col lg="12">
                            <hr />
                        </Col>
                        <Col lg="12">
                            <div className="copyright">
                                <h6>Copyright © 2021 - All Rights Reserved</h6>
                            </div>
                        </Col>
                    </Row>
                    <div className="footer-circle1"></div>
                    <div className="footer-circle2"></div>
                </Container>
            </footer>
        )
    }
}

export default Footer;