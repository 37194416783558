import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Map from '../components/map';


import moment from 'moment';
import BlogArticle from '../components/blogArticle';




class BlogDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            data: {
                "naziv": "",
                "opis": "",
                "sadrzaj": ""
            },
            differentBlogs: []
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }
    }

    render() {
        return (
            <div className="page-into">
                <section>
                    <Container className="title">
                        <Row>
                            <Col lg="12">
                                <h1>{this.state.data.naziv}</h1>
                            </Col>
                            <div className="page-circle"></div>
                            <div className="page-circle2"></div>
                            <div className="page-circle3"></div>
                            <div className="page-circle4"></div>
                            <div className="page-circle5"></div>
                            <div className="page-circle6"></div>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container className="page-content">
                        <Row>
                            <Col lg="8">
                                <div dangerouslySetInnerHTML={{ __html: this.state.data.sadrzaj }} />
                            </Col>
                            <Col lg="4">
                                <div className="right-content right-content-blog-detail">
                                    <div className="blogs">
                                        <h4>Nedavno objavljene vijesti</h4>
                                        {
                                            this.state.differentBlogs.map((diffBlog, idx) => {
                                                return (
                                                    <Link key={idx} to={"/vijesti/" + diffBlog.vijest_id}>
                                                        <div className="blog-title">
                                                            <h6>{diffBlog.naziv}</h6>
                                                        </div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="work-time">
                                        <h4>Radno vrijeme ordinacije</h4>
                                        <div className="work-days">
                                            <h6><span>Ponedjeljak - Petak:</span></h6>
                                            <h6>10:00h - 18:00h</h6>
                                        </div>
                                        <div className="work-days">
                                            <h6><span>Subota:</span></h6>
                                            <h6>Ne radimo</h6>
                                        </div>
                                        <div className="work-days">
                                            <h6><span>Nedelja:</span></h6>
                                            <h6>Ne radimo</h6>
                                        </div>
                                        <p align="justify">
                                            Pregled u estetsko-stomatološkom centru možete
                                            zakazati putem telefona
                                            email- a ili
                                            jednostavnim popunjavanjem
                                            online forme. Nakon što dobijemo
                                            Vaš zahtjev kontaktiraćemo Vas u
                                            najkraćem roku kako bi potvrdili termin.
                                        </p>
                                        <Link to="/zakazi-pregled"><button className="button">Zakažite pregled </button></Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section >
                    <Container fluid>
                        <Row className="location-map">
                            <Map {...this.props} />
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Page(BlogDetailPage);